import {SimpleQueryResult} from "../../../../../../../../graphql/apollo/SimpleQueryResult";
import {ProspectivePolicyExtra} from "shared/dist/generated/graphql/resolvers-types";
import {gql, useQuery} from "@apollo/client";

export interface GetPolicyExtrasResult {
  policySetup: {
    policyExtras: ProspectivePolicyExtra[];
  };
}

export const GET_POLICY_EXTRAS = gql`
  query GetPolicyExtras {
    policySetup {
      policyExtras {
        type
        document {
          url
        }
        options {
          optionType
          price
          description
          excessProtectedAmount {
            amount
            currency
          }
        }
      }
    }
  }
`;

export function useAvailablePolicyExtrasQuery(): SimpleQueryResult<ProspectivePolicyExtra[]> {
  const queryResult = useQuery<GetPolicyExtrasResult>(GET_POLICY_EXTRAS);

  return {
    loading: queryResult.loading,
    error: queryResult.error,
    data: queryResult.data?.policySetup.policyExtras ?? []
  };
}